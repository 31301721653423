<template>
  <div>
    <ds-header title="i友期刊绑定管理"></ds-header>
    <a-form :form="searchForm" layout="inline">
      <a-form-item label="手册名称">
        <a-input placeholder="请输入手册名称" v-model.trim="searchForm.bookName" />
      </a-form-item>
      <a-form-item label="所属项目">
        <a-select
          v-model="searchForm.projectId"
          style="width: 200px"
          placeholder="请选择所属项目"
        >
          <a-select-option :value="item.id" v-for="item in projects" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="状态">
        <a-select
          v-model="searchForm.status"
          style="width: 200px"
          placeholder="请选择状态"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="search">
          查询
        </a-button>
      </a-form-item><a-form-item>
        <a-button type="primary" @click="resetSearchForm">
          重置
        </a-button>
      </a-form-item>
    </a-form>
    <div style="margin-top: 20px">
      <a-button type="primary" @click="addBind">
        新增绑定
      </a-button>
      <a-button type="link" @click="copyTheLink">
        复制书架链接
      </a-button>
    </div>
    <div>
      <a-table
        @change="pageChange"
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination.total ? pagination : false"
      >
        <template slot="coverImgUrl" slot-scope="text, record">
          <img width="100px" height="100px" :src="text" alt="" />
        </template>
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" @click="edit(record)">编辑</a>
          <a-divider type="vertical" />
          <span>
            <a href="javascript:;" v-if="record.status === 0" @click="updateStatus(record, 1)">启用</a>
            <a href="javascript:;" v-else @click="updateStatus(record,0)">禁用</a>
          </span>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="deleteBindingBook(record)">删除</a>
        </template>
      </a-table>
    </div>
    <div>
      <a-modal
        :title="modalTitle"
        :visible="visible"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <div>
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item ref="bookId" label="手册名称" prop="bookId">
              <a-select
                v-model="form.bookId"
                placeholder="请选择手册"
                style="width: 200px"
              >
                <a-select-option :value="item.bookId" v-for="item in enableBooks" :key="item.bookId">
                  {{ item.bookName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="所属项目" prop="projectIds">
              <a-select
                v-model="form.projectIds"
                placeholder="请选择所属项目"
                mode="multiple"
                style="width: 200px"
              >
                <a-select-option :value="item.id" v-for="item in projects" :key="item.id">
                  {{ item.project_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="封面图" prop="coverImgUrl">
              <a-upload
                :action="IMG_API + '/oss/files'"
                list-type="picture-card"
                :file-list="imgList"
                @preview="handlePreview"
                :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
                :before-upload="imgBeforeUpload"
                @change="handleChange"
              >
                <div  v-if="imgList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    Upload
                  </div>
                </div>
              </a-upload>
              <span>建议上传3:4比例的jpg/png格式文件，且大小不超过500kb</span>
            </a-form-model-item>
            <a-form-model-item label="优先级" prop="sort">
              <a-input-number
                v-model="form.sort"
                :max="999"
                :min="1"
                style="width: 100px"
                :precision="0"
              />
              <div>支持输入1～999，优先级越小展示越靠前</div>
            </a-form-model-item>

            <a-form-model-item label="状态" :required="true">
              <a-radio-group
                v-model="form.status"
              >
                <a-radio :value="1">启用</a-radio>
                <a-radio :value="0">禁用</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  addBindBook,
  findAllEnableBooks, findBindingById,
  getIyouPeriodicalList, removeBindingBook, updateBindingBook,
  updateBindingBookStatus
} from "@/api/serviceManualManagement";
import {projectName} from "@/api/serviceBinding";
import {IMG_API} from "@/config";

export default {
  data() {
    return {
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      imgList: [],
      IMG_API: IMG_API,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      modalTitle: '新增',
      // 弹窗的展示隐藏
      visible: false,
      // 所有项目
      projects: [],
      // 可绑定的手册
      enableBooks: [],
      // 搜索条件
      searchForm: {
        bookName: '',
        status: undefined,
        projectId: undefined
      },
      // 列表数据
      tableData: [],
      columns: [
        {
          title: '封面图',
          dataIndex: 'coverImgUrl',
          width: '10%',
          scopedSlots: { customRender: 'coverImgUrl' },
        },
        {
          title: '手册名称',
          dataIndex: 'handBookName',
          width: '10%',
        },
        {
          title: '所属项目',
          dataIndex: 'projectNames',
          width: '10%'
        },
        {
          title: '优先级',
          dataIndex: 'sort',
          width: '10%'
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          width: '10%'
        },
        {
          title: '操作人',
          dataIndex: 'updateUserName',
          width: '10%'
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: '10%',
          customRender(text) {
            return text === 1 ? '启用' : '禁用'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        }
      ],
      form: {
        bookId: undefined,
        projectIds: undefined,
        coverImgUrl: '',
        sort: '',
        status: 1
      },
      rules: {
        bookId: [
          { required: true, message: '请选择手册名称', trigger: 'change' }
        ],
        projectIds: [
          { required: true, message: '请选择所属项目', trigger: 'change' }
        ],
        coverImgUrl: [
          { required: true, message: '请上传封面图', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入优先级', trigger: 'blur' }
        ]
      }
    }
  },
  async created() {
    // 查询i友周刊列表
    await this.getList()
    // 查询所有项目
    await this.getProjects()
    // 查询可绑定的手册
    await this.selectEnableBooks()
  },
  methods: {
    // 获取所有项目信息
    async getProjects() {
      const res = await projectName()
      const { code, data: { items } } = res
      if (code === '200') {
        this.projects = items
      }
    },
    // 查询列表
    async getList() {
      const params = this.getParams()
      const res = await getIyouPeriodicalList(params);
      const { code, msg, data: { data, total } } = res
      if (code === '200') {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.warning(msg)
      }
    },
    // 整理列表的参数
    getParams() {
      return {
        ...this.searchForm,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
    },
    // 查询
    search() {
      this.getList()
    },
    // 重置
    resetSearchForm() {
      this.searchForm.bookName = ''
      this.searchForm.status = undefined
      this.searchForm.projectId = undefined
      this.pagination.current = 1
      this.getList()
    },
    // 新增绑定
    async addBind() {
      this.form = {
        bookId: undefined,
        projectIds: undefined,
        coverImgUrl: '',
        sort: '',
        status: 1
      }
      this.imgList = []
      setTimeout(() => {
        this.visible = true
      }, 200)
    },
    // 编辑
    async edit(row) {
      await this.findOneById(row.bookBindingId)
    },
    // 查询详情
    async findOneById(id) {
      const res = await findBindingById(id)
      const { code, msg, data } = res
      if (code === '200') {
        this.form = data
        this.imgList = [{uid: '-1',url: data.coverImgUrl}]
        this.visible = true
      } else {
         this.$message.error(msg)
      }

    },
    // 复制链接
    copyTheLink() {
      const env = process.env.NODE_ENV
      let url = '';
      if (env === 'production') {
        url = `https://yuanqu.innoecos.cn/apps/activity/bookShelf`
      } else {
        url = `https://yuanqu-sit.innoecos.cn/apps/activity/bookShelf`
      }
      let oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message.success("复制成功！")
      oInput.remove()
    },
    // 分页
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 查询所有可绑定的手册
    async selectEnableBooks() {
      const res = await findAllEnableBooks();
      const { code, data } = res
      if (code === '200') {
        this.enableBooks = data
      }
    },
    // 禁用和启用
    async updateStatus(row, status) {
      const that = this
      const params = {
        bookBindingId: row.bookBindingId,
        status
      }
      await this.$confirm({
        title: `确定要${status ? '启用' : '禁用'}该条数据吗？`,
        async onOk() {
          const res = await updateBindingBookStatus(params)
          const {code, msg} = res
          if (code === '200') {
            that.$message.success('操作成功！')
            that.pagination.current = 1
            await that.getList()
          } else {
            that.$message.warning(msg)
          }
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    // 删除
    async deleteBindingBook(row) {
      const that = this
      await this.$confirm({
        title: '删除：是否确认解除该条数据中项目和i友期刊之间的绑定？',
        async onOk() {
          const res = await removeBindingBook(row.bookBindingId)
          const {code, msg} = res
          if (code === '200') {
            that.$message.success('操作成功！')
            that.pagination.current = 1
            await that.getList()
          } else {
            that.$message.warning(msg)
          }
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    // 保存
    async handleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          let res = {}
          if (this.form.bookBindingId) {
            res = await updateBindingBook(this.form)
          } else {
            res = await addBindBook(this.form)
          }
          const {code, msg} = res
          if (code === '200') {
            this.$message.success('操作成功！')
            this.pagination.current = 1
            await this.getList()
            this.visible = false
          } else {
            this.$message.error(msg)
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    handleCancel() {
      this.visible = false
    },
    // 浏览图片
    async handlePreview(file) {
      if (!file.url && !file.preview) {
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      const that = this
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg";
      if (!isJPG) {
        that.$message.error("上传图片只能是jpg、png格式!");
        return new Promise(function (resolve, reject) {return reject});
      }
      const isLt2M = file.size/1024 < 500
      if (!isLt2M) {
        that.$message.error("上传图片不能大于500KB!");
        return new Promise(function (resolve, reject) {return reject});
      }
      return isJPG && isLt2M;
    },
    // 图片上传
    handleChange({ fileList }) {
      this.imgList = fileList;
      console.log(fileList, '@@@')
      if (fileList && fileList.length && fileList[0].status === "done") {
        this.loading = false;
        this.form.coverImgUrl = fileList[0].response.redirect_uri
        this.imgList = [{uid: '-1',url: fileList[0].response.redirect_uri}]
      }
      if (fileList.length === 0) {
        this.form.coverImgUrl = ''
        this.imgList.length = 0
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
